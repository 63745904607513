import React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TableData from '../../custom/Table/TableData';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import { basketState } from '../../../state/basketState';
import { useRecoilState } from "recoil";
import { Alert, Divider, TextField } from '@mui/material';
import ScaleIcon from '@mui/icons-material/Scale';
import WbIridescentIcon from '@mui/icons-material/WbIridescent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import basketService from '../../../api/basket';
import ConfirmDialog from '../../custom/ConfirmDialog/ConfirmDialog';
import InfoDialog from '../../custom/InfoDialog/InfoDialog';


function BasketPage() {

    const { lang, l } = useI18n();
    //const [basket, setBasket] = React.useState([]);
    const [basketRecoil, setBasketRecoil] = useRecoilState(basketState);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
    const { user } = useUserInfo();
    const [comment, setComment] = React.useState('');
    
    React.useEffect(() => {

        setBasketRecoil({ basket: {} });

        const GetBasket = async () => {
            try {

                const basket = await basketService.findAllByUserId(user.CODE, lang);

                setOpenAlertDialog(!basket?.math?.find((item) => item.QUANT < item.FMINQTY))

                setBasketRecoil({ basket });
                console.log('basket :>> ', basket);
            } catch (error) {
                console.error('Error findAllByUserId >> ', error);

            }
        };

        if (user) GetBasket();

    }, [user]);

    const handleDelete = () => {

        const fetcData = async () => {

            try {
                await basketService.deleteBasket();
                clearBasket();
            } catch (error) {
                console.error('Error deleteBasket >> ', error);
            }
        };

        fetcData();

        setOpenConfirmDialog(false)
    };

    const handleConfirm = () => {

        const fetcData = async () => {

            try {
                const data = { ...basketRecoil.basket, comment };
                const res = await basketService.confirmBasket(data);

                if(res) {
                    setOpenSuccessDialog(true);
                }
               
                clearBasket();
            } catch (error) {
                console.error('Error deleteBasket >> ', error);
            }
        };

        fetcData();
    };

    const clearBasket = () => {
        const newBasket = {
            basket: {},
        };
        setBasketRecoil(newBasket);
    };

    const columns = [
        { id: 'QTARTCODE', label: l('Code'), minWidth: 150, type: CellType.STRING },
        { id: 'FNAME', label: l('Name'), minWidth: 200, type: CellType.PRODUCT_NAME },
        { id: 'FIMG', label: l('Image'), minWidth: 80, type: CellType.IMG, align: 'center' },
        { id: 'FPRICE1', label: l('Price'), minWidth: 100, type: CellType.PRISE_WITH_DISCOUNT },
        { id: 'QUANT', label: l('Quantity'), minWidth: 80, type: CellType.EDITABLE_NUMBER },
        { id: 'ITEMSUM', label: l('Sum'), minWidth: 80, type: CellType.PRODUCT_SUM },
        { id: 'Action', label: l('Action'), minWidth: 80, type: CellType.ACTIONS },
    ];

    const debounceRefEditableNumbers = React.useRef(null);
    const handelAction = (event, type, row) => {

        const { value } = event.target;

        switch (type) {
            case CellType.EDITABLE_NUMBER:

                if(value === '0') {
                    event.target.value = 1;
                    return;
                }

                if (debounceRefEditableNumbers.current) {
                    clearTimeout(debounceRefEditableNumbers.current);
                }

                debounceRefEditableNumbers.current = setTimeout(() => {
                    const newRow = { ...row };

                    newRow.QUANT = value || 1;
                    const discount = newRow.FDISCOUNT || 0;
                    let priceWithDiscount = Math.round((newRow.FPRICE1 - (newRow.FPRICE1 * discount / 100)) * 100) / 100;
                    priceWithDiscount = Math.round(priceWithDiscount * newRow.QUANT * 100) / 100;
                    newRow.ITEMSUM = priceWithDiscount;
                    newRow.SUMFWEIGHT = Math.round(newRow.FWEIGHT * newRow.QUANT * 1000) / 1000;
                    newRow.SUMFVOLUME = Math.round(newRow.FVOLUME * newRow.QUANT * 100000) / 100000;

                    if (user) {
                        try {
                            const fetcData = async () => {

                                const newBasket = await basketService.updateBasket(newRow);

                                setOpenAlertDialog(!newBasket?.math?.find((item) => item.QUANT < item.FMINQTY))

                                setBasketRecoil({ basket: newBasket || {} });
                            };

                            fetcData();

                        } catch (error) {
                            console.error('Error update Basket :>> ', error);
                        }
                    }

                }, 500); // Затримка у 500 мс

                break;

                case CellType.ACTIONS:
                    const newRow = { ...row };

                    newRow.QUANT = 0;

                    if (user) {
                        try {
                            const fetcData = async () => {

                                const newBasket = await basketService.updateBasket(newRow);

                                setOpenAlertDialog(!newBasket?.math?.find((item) => item.QUANT < item.FMINQTY))

                                setBasketRecoil({ basket: newBasket || {} });
                            };

                            fetcData();

                        } catch (error) {
                            console.error('Error update Basket :>> ', error);
                        }
                    }

                break;

            default:
                break;
        }
    }


    return (
        <>
            <Box display="flex" flexDirection='column' sx={{ mt: 2 }} gap={2}>
                <Box display="flex" gap={2} justifyContent="space-between">
                    <Box display="flex" gap={2}>
                        <Typography component="h2" variant="h5">
                            {l('Confirm the shipment of the Order')}
                        </Typography>

                        <Divider orientation="vertical" flexItem />

                        <Typography component="h6" variant="h6">
                            {l('Total')}:
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <AttachMoneyIcon />
                            <span style={{ color: "green" }}>
                                {basketRecoil?.basket?.totsum}$
                            </span>
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <ScaleIcon />
                            <span style={{ color: "#dc3545", marginLeft: 5 }}>
                                {basketRecoil?.basket?.totweight}{l('Kg')}
                            </span>
                        </Typography>

                        <Typography display="flex" alignItems="center" component="h6" variant="h6">
                            <WbIridescentIcon />
                            <span style={{ color: "#007bff", marginLeft: 5 }}>
                                {basketRecoil?.basket?.totvolume}{l('m³')}
                            </span>
                        </Typography>
                    </Box>

                    <Box>
                        <Button disabled={basketRecoil?.basket?.totsum < 75 || !basketRecoil?.basket?.math?.length || !openAlertDialog}
                            sx={{
                                mr: 2,
                                "&.Mui-disabled": {
                                    color: "white",
                                }
                            }}
                            variant="contained"
                            autoFocus
                            color="success"
                            onClick={handleConfirm}
                        >
                            {l('Send')}
                        </Button>

                        <Button
                            sx={{
                                "&.Mui-disabled": {
                                    color: "white",
                                }
                            }}
                            disabled={!basketRecoil?.basket?.math?.length}
                            variant="contained"
                            autoFocus
                            color="error"
                            onClick={() => setOpenConfirmDialog(true)}>
                            {l('Clear basket')}
                        </Button>
                    </Box>

                </Box>
                <Box>
                    <TableData
                        columns={columns}
                        rows={basketRecoil?.basket?.math || []}
                        cellBtnActionByType={handelAction}
                    />
                    {basketRecoil?.basket?.math?.length === 0 || !basketRecoil?.basket?.math ? <Typography marginTop={2} color='grey' textAlign='center' component="p" variant="h5">{l('Basket is empty')}</Typography> : ''}
                </Box>

                {basketRecoil?.basket?.totsum < 75 && <Alert severity="error">{l('The order amount must be at least $75')}</Alert>}
                {!openAlertDialog && basketRecoil?.basket?.math ? <Alert severity="error">{l('The number of selected products is less than the minimum')}</Alert> : ''}

                <Box width='100%'>
                    <TextField
                        fullWidth
                        onChange={(e) => setComment(e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                        multiline
                        rows={1}
                        label={`${l("Your comment")}...`}
                        variant="outlined"
                        inputProps={{ maxLength: 125 }}
                    />
                    <Typography color='#515151' marginTop={1} component="p" variant="p">
                        {l('Number of characters')} {comment.length} {l('of')} 125
                    </Typography>
                </Box>
            </Box>
            <ConfirmDialog
                handleConfirm={handleDelete}
                open={openConfirmDialog}
                handleClose={() => setOpenConfirmDialog(false)}
                title={`⚠️ ${l('Clear basket')}`}
                contentText={`${l('Are you sure that you want to clear the basket, all products that were selected will be deleted')}!`}
                denyButtonText={l('Cancel')}
                confirmButtonText={l('Agree')}
            />
            <InfoDialog
                handleConfirm={() => setOpenSuccessDialog(false)}
                open={openSuccessDialog}
                handleClose={() => setOpenSuccessDialog(false)}
                title={`${l('Information')}`}
                contentText={`${l('Thank you for the order')}! ${l('Our manager will contact you as soon as possible')}.`}
                confirmButtonText={l('OK')}
            />
        </>
    );
}

export default BasketPage;